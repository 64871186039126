import './js/index';
import './scss/index.scss';

const bootstrap = require('bootstrap');
// Full import of fontawesome icons
import './../../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss';
// import './../../../node_modules/@fortawesome/fontawesome-free/scss/';
// import './../../../node_modules/@fortawesome/fontawesome-free/js/all';

const $ = require('jquery');
const Noty = require('noty');

$('.hamburger').on('click', function () {
  $(this).toggleClass('is-active');
});

$('.tel-pc').on('click', function (e) {
  e.preventDefault();
  $(this).toggleClass('activated');
})

$.each($('.noty-flash-message'), function (key, noty) {
  new Noty({
    type: $(noty).attr('data-type'),
    text: $(noty).attr('data-message'),
    theme: 'mint',
    timeout: 5000 + (key * 1000),
  }).show();
});

// let recaptchaFields = $('.recaptcha');
// if(recaptchaFields.length > 0) {
//   grecaptcha.ready(function () {
//     grecaptcha.execute(recaptchaFields.attr('data-recaptcha-key'), {action: 'homepage'}).then(function (token) {
//       recaptchaFields.val(token);
//     });
//   });
// }

document.addEventListener("DOMContentLoaded", function () {
  var lazyloadImages;

  if ("IntersectionObserver" in window) {
    lazyloadImages = document.querySelectorAll("[data-lazybackground],[data-lazyimg]");
    var imageObserver = new IntersectionObserver(function (entries, observer) {
      entries.forEach(function (entry) {
        if (entry.isIntersecting) {
          var image = entry.target;
          if (image.hasAttribute("data-lazybackground")) {
            setBackground(image);
          } else if (image.hasAttribute("data-lazyimg")) {
            setSrc(image);
          }
          imageObserver.unobserve(image);
        }
      });
    });

    lazyloadImages.forEach(function (image) {
      imageObserver.observe(image);
    });
  } else {
    var lazyloadThrottleTimeout;
    lazyloadImages = document.querySelectorAll("[data-lazybackground],[data-lazyimg]");

    function lazyload() {
      if (lazyloadThrottleTimeout) {
        clearTimeout(lazyloadThrottleTimeout);
      }

      lazyloadThrottleTimeout = setTimeout(function () {
        var scrollTop = window.pageYOffset;
        lazyloadImages.forEach(function (img) {
          if (img.offsetTop >= (window.innerHeight + scrollTop)) {
            return false;
          }
          if (image.hasAttribute("data-lazybackground")) {
            setBackground(image);
          } else if (image.hasAttribute("data-lazyimg")) {
            setSrc(image);
          }
        });
        if (lazyloadImages.length == 0) {
          document.removeEventListener("scroll", lazyload);
          window.removeEventListener("resize", lazyload);
          window.removeEventListener("orientationChange", lazyload);
        }
      }, 20);
    }

    document.addEventListener("scroll", lazyload);
    window.addEventListener("resize", lazyload);
    window.addEventListener("orientationChange", lazyload);
  }
})

function setBackground(image) {
  image.style.backgroundImage = `url(${image.dataset.lazybackground})`;
}

function setSrc() {
  image.src = image.dataset.lazyimg;
}
